import { DateTime } from 'luxon'
import ScopeMap from './scope-map'
import i18n from '../i18n'

export function getDateObject(dt, level = ScopeMap.day) {
  return {
    year: level === ScopeMap.week ? dt.weekYear : dt.year,
    month: level === ScopeMap.month || level === ScopeMap.day ? dt.month : null,
    week: level === ScopeMap.week ? dt.weekNumber : null,
    day: level === ScopeMap.day ? dt.day : null,
  }
}

export function getDTfromDateObject(dateObject, toJSON) {
  if (!dateObject) return { dateScheduled: null, dateUnit: null }

  const { year, month, week, day } = dateObject

  let dt = DateTime.fromObject({ year, month, day })
  let dateUnit = ScopeMap.year

  if (day) {
    dateUnit = ScopeMap.day
  } else if (week) {
    dt = DateTime.fromObject({ weekYear: year, weekNumber: week })
    dateUnit = ScopeMap.week
  } else if (month) {
    dateUnit = ScopeMap.month
  }

  return {
    dateScheduled: toJSON ? absoluteDt(dt).toJSDate() : dt,
    dateUnit: dateUnit,
  }
}

export function dtToString(dt, level) {
  if (!dt) return null

  if (level === ScopeMap.week) {
    return i18n.t('other.convert-dates.week-weeknumber-of-weekyear', {
      weekNumber: dt.weekNumber,
      weekYear: dt.weekYear,
    })
  } else if (level === ScopeMap.year) {
    return dt.toLocaleString({
      year: 'numeric',
    })
  } else if (level === ScopeMap.month) {
    return dt.toLocaleString({
      month: 'long',
      year: 'numeric',
    })
  } else {
    return dt.toLocaleString(DateTime.DATE_FULL)
  }
}

export const absoluteDt = dt => {
  return DateTime.utc(
    dt.year,
    dt.month,
    dt.day,
    dt.hour,
    dt.minute,
    dt.second,
    dt.millisecond
  )
}
