<template>
  <div
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    ref="dayEntry"
    class="day-entry js-day-entry d-flex align-start"
    :data-id="entry.id"
  >
    <v-icon
      v-if="entry.type === 'task'"
      class="mr-2 mt-1 js-entry-icon"
      small
      @click="toggleComplete"
    >
      <template v-if="complete">
        fas fa-check-square
      </template>
      <template v-else>
        far fa-square
      </template>
    </v-icon>
    <v-icon v-else class="mr-2" small>
      {{ entryTypesMap[entry.type] && entryTypesMap[entry.type].icon }}
    </v-icon>

    <div class="flex-grow-1">
      <div class="d-flex js-day-entry__top-row">
        <textarea
          ref="autoSizeTextarea"
          class="title-input js-title-input"
          :value="title"
          rows="1"
          @change="updateTitle"
          @input="autoSizeTextarea"
          @keypress.enter.exact="titleInputEnterPressed"
          @keydown.delete="archiveEntry"
          @focus="textInputFocused = true"
          @blur="textInputFocused = false"
        ></textarea>

        <v-menu open-on-hover bottom offset-y auto>
          <template v-slot:activator="{ on }">
            <v-btn class="js-hide-hovered hidden mr-1" icon x-small v-on="on">
              <v-icon small>
                fas fa-angle-double-right
              </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(option, i) in migrationOptions"
              :key="i"
              @click="migrateEntry(option.dateObject)"
            >
              <v-list-item-title>{{ option.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-menu
          v-if="reminderOptions.length"
          open-on-hover
          bottom
          offset-y
          auto
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-1 js-hide-hovered hidden"
              icon
              x-small
              v-on="on"
              :loading="reminderLoading"
            >
              <v-icon small>
                far fa-bell
              </v-icon>
            </v-btn>
          </template>

          <v-list dense v-if="!reminderLoading">
            <template v-if="entry.reminder">
              <v-list-item>
                <v-list-item-title>
                  <v-icon x-small>far fa-bell</v-icon>
                  {{ entry.reminder.dateScheduled.toDate().toLocaleString() }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn
                    icon
                    x-small
                    @click="callDeleteReminder"
                    :loading="deleteReminderLoading"
                    ><v-icon x-small>fas fa-times</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
            <v-list-item
              v-for="(option, i) in reminderOptions"
              :key="i"
              @click="callSetReminder(option.dt)"
            >
              <v-list-item-title>{{ option.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->

        <v-btn
          ref="deetBtn"
          class="mr-1 js-hide-hovered hidden"
          icon
          x-small
          @click="SET_SHOW_ENTRY(entry.id)"
        >
          <v-icon small>
            fas fa-info-circle
          </v-icon>
        </v-btn>

        <v-btn
          v-if="orderable"
          x-small
          icon
          class="day-entry__drag-handle js-drag-handle js-hide-hovered hidden"
        >
          <v-icon small>
            fas fa-grip-vertical
          </v-icon>
        </v-btn>
      </div>

      <div
        class="d-flex text-caption day-entry__subrow grey--text no-scrollbar"
      >
        <slot v-bind="{ dtScheduled }">
          <router-link
            v-if="context !== 'prompt' && entry.type === 'note' && prompt"
            class="mr-3 no-styles"
            :to="{ name: 'prompt', params: { promptId: prompt.id } }"
          >
            {{ prompt.text }}
          </router-link>

          <v-menu
            v-if="entry.content"
            :close-on-content-click="false"
            offset-y
            max-width="400"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="mr-3 clickable">
                <v-icon x-small>fas fa-align-left</v-icon>
              </div>
            </template>

            <v-card class="pa-2 text-caption text--content">
              {{ entry.content }}
            </v-card>
          </v-menu>

          <v-menu
            v-if="entry.numImages"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="mr-3 clickable">
                <v-icon x-small>fas fa-images</v-icon>
              </div>
            </template>

            <v-card class="pa-2">
              <v-row class="d-flex" dense>
                <v-col v-for="(image, i) in images" :key="i" cols="12" md="4">
                  <EntryImage
                    :image="image"
                    @deleteBtnClicked="deleteEntryImage(image.id)"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-menu>

          <v-icon
            v-if="
              entry.type === 'task' && entry.parentId && context !== 'subtasks'
            "
            class="mr-3"
            x-small
            @click="SET_SHOW_ENTRY(entry.parentId)"
            >fas fa-level-up-alt</v-icon
          >

          <v-menu
            v-if="entry.type === 'task' && subtasks.length"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="mr-3 clickable">
                <v-icon x-small>fas fa-tasks</v-icon>
                {{ completeSubtasks.length }}/{{ subtasks.length }}
              </div>
            </template>

            <v-card class="pa-2 subtasks" width="350">
              <EntryList
                :entries="subtasks"
                context="subtasks"
                @addNewEntry="addSubtask"
              />
            </v-card>
          </v-menu>

          <router-link
            v-if="dtScheduled && !['date', 'week'].includes(context)"
            class="mr-3 no-styles"
            :to="{
              name: 'dashboard',
              query: { date: dtScheduled.toISODate() },
            }"
          >
            <v-icon x-small class="mr-1">far fa-calendar-alt</v-icon>
            {{ dtToString(dtScheduled, level) }}
          </router-link>

          <div v-if="entry.type === 'task' && rruleText" class="mr-3">
            <v-icon class="mr-1" x-small>fas fa-sync</v-icon>
            {{ rruleText }}
          </div>

          <template v-if="list && context !== 'list'">
            <router-link
              :to="{ name: 'list', params: { listId: entry.list } }"
              class="mr-3 no-styles"
              :style="{ color: minimal ? '' : list.color }"
            >
              <v-icon x-small :color="minimal ? '' : list.color"
                >fas fa-list</v-icon
              >
              {{ list.title }}
            </router-link>
          </template>

          <div class="mr-3">
            <router-link
              v-for="tag in entryTags"
              :key="tag.id"
              :to="{ name: 'tag', params: { tagId: tag.id } }"
              :style="{ color: minimal ? '' : tag.color }"
              class="day-entry__tag no-styles"
              >{{ tag.id }}</router-link
            >
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  getDateObject,
  getDTfromDateObject,
  dtToString,
} from '../helpers/convert-dates'
import isEqual from 'lodash/isEqual'
import scopeMap from '../helpers/scope-map'
import eventBus from '@/eventBus'

import EntryImage from '@/components/EntryImage'
import { EntriesDatabase } from '@/services/database'

export default {
  name: 'DayEntry',

  components: {
    EntryList: () => import('@/components/EntryList'),
    EntryImage,
  },

  props: {
    entry: {
      required: true,
      type: Object,
    },
    orderable: {
      required: false,
      type: Boolean,
      default: true,
    },
    context: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      subtasks: [],
      subtasksObservable$: null,
      dialog: false,
      hovered: false,
      textInputFocused: false,
      reminderLoading: false,
      deleteReminderLoading: false,
    }
  },

  computed: {
    ...mapGetters('lists', ['listMap']),
    ...mapState('tags', ['tags']),
    ...mapState('entries', ['entryTypesMap', 'entryImages']),
    ...mapState('repeaters', ['repeaters']),
    ...mapState('prompts', ['prompts']),
    ...mapState('app', ['minimal']),
    ...mapGetters('auth', ['isPremium']),

    complete() {
      return !!this.entry.dateComplete
    },

    dtScheduled() {
      return (
        this.entry.dateScheduled &&
        this.$DateTime.fromJSDate(this.entry.dateScheduled.toDate())
      )
    },

    level() {
      return this.entry.dateUnit
    },

    migrationOptions() {
      const today = this.$DateTime.local()

      return [
        {
          text: this.$t('components.day-entry.today'),
          dateObject: getDateObject(today),
        },
        {
          text: this.$t('components.day-entry.tomorrow'),
          dateObject: getDateObject(today.plus({ days: 1 })),
        },
        {
          text: this.$t('components.day-entry.this-week'),
          dateObject: getDateObject(today, scopeMap.week),
        },
        {
          text: this.$t('components.day-entry.next-week'),
          dateObject: getDateObject(today.plus({ weeks: 1 }), scopeMap.week),
        },
        {
          text: this.$t('components.day-entry.next-month'),
          dateObject: getDateObject(today.plus({ months: 1 }), scopeMap.month),
        },
      ].filter(
        option =>
          !this.entry.dateScheduled ||
          !isEqual(
            option.dateObject,
            getDateObject(
              this.entry.dateScheduled.toDate(),
              this.entry.dateUnit
            )
          )
      )
    },

    list() {
      return this.entry.list && this.listMap[this.entry.list]
    },

    entryTags() {
      return this.tags.filter(
        t => this.entry.tags && this.entry.tags.includes(t.id)
      )
    },

    shouldShowActions() {
      return this.hovered || this.textInputFocused
    },

    completeSubtasks() {
      return this.subtasks.filter(e => e.dateComplete)
    },

    rruleText() {
      const repeaterId = this.entry.repeaterId

      if (!repeaterId) return null

      const repeater =
        repeaterId && this.repeaters.find(r => r.id === repeaterId)

      return repeater && repeater.text
    },

    prompt() {
      return this.prompts.find(p => p.id === this.entry.promptId)
    },

    images() {
      return this.entryImages[this.entry.id] || []
    },

    title() {
      return this.entry.title // needed for autosize
    },
  },

  watch: {
    title: function() {
      this.$nextTick(() => {
        this.autoSizeTextarea()
      })
    },

    shouldShowActions: function(value) {
      const topRow = this.$refs.dayEntry.querySelector('.js-day-entry__top-row')
      const toHide = topRow.querySelectorAll('* > .js-hide-hovered')

      toHide.forEach(e => {
        if (value) {
          e.classList.remove('hidden')
        } else {
          e.classList.add('hidden')
        }
      })
    },
  },

  created() {
    this.getImages({ entryId: this.entry.id })
    this.getSubtasks()
  },

  mounted() {
    const el = this.$refs.autoSizeTextarea

    const interval = setInterval(() => {
      if (el.scrollHeight) {
        el.setAttribute(
          'style',
          'height:' + el.scrollHeight + 'px;overflow-y:hidden;'
        )
        clearInterval(interval)
      }
    }, 10)

    this.$nextTick(() => {
      this.autoSizeTextarea()
    })
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('entries', [
      'updateEntry',
      'setReminder',
      'deleteReminder',
      'newEntry',
      'getImages',
      'deleteImage',
      'setComplete',
      'setIncomplete',
    ]),
    ...mapMutations('entries', ['SET_SHOW_ENTRY']),
    dtToString,
    getDTfromDateObject,

    disposeListener() {
      if (this.subtasksObservable$) this.subtasksObservable$.unsubscribe()
    },

    getSubtasks() {
      this.disposeListener()

      this.subtasksObservable$ = EntriesDatabase.observeSubtasksEntries$(
        this.entry.id,
        data => {
          this.subtasks = data
        }
      )
    },

    autoSizeTextarea() {
      const el = this.$refs.autoSizeTextarea

      el.style.height = 'auto'
      el.style.height = el.scrollHeight + 'px'
    },

    titleInputEnterPressed(e) {
      e.target.blur()
      this.$emit('titleInputEnterPressed')
    },

    updateTitle(e) {
      this.updateEntry({
        id: this.entry.id,
        data: {
          title: e.target.value,
        },
      })
    },

    archiveEntry(e) {
      if (!e.target.value) {
        this.updateEntry({
          id: this.entry.id,
          data: {
            archived: true,
          },
        })
      }
    },

    toggleComplete() {
      if (this.entry.dateComplete) {
        this.setIncomplete(this.entry.id)
      } else {
        if (this.entry.repeaterId && !this.isPremium) {
          eventBus.$emit(
            'toast',
            'Upgrade to premium to enable repeating tasks'
          )
        }
        this.setComplete(this.entry.id)
      }
    },

    migrateEntry(dateObject) {
      this.updateEntry({
        id: this.entry.id,
        data: {
          ...this.getDTfromDateObject(dateObject, true),
        },
      })
    },

    async addSubtask() {
      const newEntryDoc = await this.newEntry({
        parentId: this.entry.id,
      })

      await this.$nextTick()

      const el = document.querySelector(
        `.subtasks .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
      )

      el.focus()
    },

    async callSetReminder(dt) {
      this.reminderLoading = true
      await this.setReminder({ entryId: this.entry.id, dt })
      this.reminderLoading = false
    },

    async callDeleteReminder() {
      this.deleteReminderLoading = true
      await this.deleteReminder(this.entry.id)
      this.deleteReminderLoading = false
    },

    async deleteEntryImage(imageId) {
      if (confirm(this.$t('components.day-entry.delete-image-warning'))) {
        this.deleteImage({ entryId: this.entry.id, imageId })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.day-entry {
  position: relative;
  cursor: auto;
  &__drag-handle {
    cursor: move;
    cursor: drag;
  }
  &__tag {
    margin-right: 2px;
  }
  &__subrow {
    overflow-x: auto;

    > * {
      flex-shrink: 0;
    }
    * {
      color: rgb(170, 170, 170);
    }
  }
  &__subrow-icon {
    font-size: 10px;
    margin-top: -2px;
  }
}
.entry-dialog-btn {
  display: none;
}
.title-input {
  width: 100%;
  border: none;
  overflow-y: hidden;
  padding: 0;
  outline: none;
  resize: none;
}
</style>
