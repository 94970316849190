<template>
  <v-dialog v-model="dialog" width="auto ">
    <template v-slot:activator="{ on }">
      <slot v-bind="on">
        <v-img v-on="on" :src="src" class="lightbox-image__img clickable" />
      </slot>
    </template>

    <v-sheet v-if="dialog">
      <img :src="src" class="lightbox-image__lightbox-img" />
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.lightbox-image {
  &__lightbox-img {
    max-height: 90vh;
    object-fit: contain;
    display: block;
    width: auto;
  }
}
</style>
