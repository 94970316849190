<template>
  <div>
    <ul class="no-styles">
      <draggable
        v-model="orderedEntries"
        :group="group"
        handle=".js-drag-handle"
        @add="reorderSave"
        @end="reorderSave"
      >
        <li v-for="entry in orderedEntries" :key="entry.id" class="my-1">
          <slot v-bind="{ entry }">
            <DayEntry
              :entry="entry"
              :orderable="ordered"
              :context="context"
              @titleInputEnterPressed="$emit('addNewEntry')"
            />
          </slot>
        </li>
      </draggable>
    </ul>

    <v-btn
      v-if="addBtn && !mainEntries.length"
      class="btn--content-left"
      color="grey"
      text
      block
      @click="$emit('addNewEntry')"
    >
      <v-icon small class="mr-2">fas fa-plus</v-icon>
      <slot name="addBtnText">
        {{ $t('components.entry-list.new-task') }}
      </slot>
    </v-btn>

    <div v-if="completeTasks.length" class="mt-4">
      <v-btn
        x-small
        @click="toggleShowComplete"
        color="transparent"
        class="elevation-0 "
        :class="{ 'grey--text': !showComplete }"
      >
        {{
          $t('components.entry-list.num-completed', {
            numComplete: completeTasks.length,
          })
        }}
        <v-icon right x-small
          >fa {{ showComplete ? 'fa-caret-up' : 'fa-caret-down' }}</v-icon
        >
      </v-btn>

      <ul v-if="showComplete" class="no-styles mt-2 translucent">
        <li v-for="entry in completeTasks" :key="entry.id" class="my-1">
          <DayEntry :entry="entry" :orderable="false" :context="context" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { orderEntries } from '@/helpers/order-entries'

import draggable from 'vuedraggable'
import DayEntry from '@/components/DayEntry'

export default {
  name: 'EntryList',

  components: {
    DayEntry,
    draggable,
  },

  props: {
    entries: {
      required: true,
      type: Array,
    },
    group: {
      required: false,
      default: 'entries',
    },
    context: {
      required: true,
      type: String,
    },
    updateData: {
      required: false,
      default: null,
      type: Object,
    },
    addBtn: {
      required: false,
      default: true,
      type: Boolean,
    },
    ordered: {
      required: false,
      default: true,
      type: Boolean,
    },
    separateComplete: {
      required: false,
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      showComplete: false,
      orderedEntries: [],
    }
  },

  computed: {
    mainEntries() {
      return this.entries.filter(
        e => !this.separateComplete || !(e.type === 'task' && e.dateComplete)
      )
    },

    completeTasks() {
      return this.entries
        .filter(e => this.separateComplete && e.dateComplete)
        .sort((a, b) => a.dateComplete.toDate() - b.dateComplete.toDate())
    },
  },

  watch: {
    mainEntries: {
      handler: function(newVal) {
        this.orderedEntries = orderEntries(newVal.slice(0), this.context, true)
      },

      immediate: true,
    },
  },

  created() {
    this.setShowComplete()
  },

  methods: {
    ...mapActions('entries', [
      'reorderEntriesSave',
      'subtaskEntries',
      'unSubtaskEntries',
    ]),

    setShowComplete() {
      const showCompleteStored = localStorage.getItem(
        `showComplete-${this.context}`
      )
      const showComplete = showCompleteStored === 'true'
      this.showComplete = showComplete
    },

    async reorderSave() {
      await this.reorderEntriesSave({
        entries: this.orderedEntries,
        context: this.context,
        updateData: this.updateData,
      })
    },

    toggleShowComplete() {
      const newVal = !this.showComplete
      this.showComplete = newVal
      localStorage.setItem(`showComplete-${this.context}`, newVal)
    },
  },
}
</script>
