<template>
  <LightboxImage :src="image.src" v-slot="on">
    <v-card v-on="on" class="entry-image__card" v-if="image.src" outlined>
      <v-img :src="image.src" class="entry-image__img" aspect-ratio="1.6">
        <div class="d-flex justify-end">
          <v-btn
            icon
            small
            @click.stop="$emit('deleteBtnClicked')"
            class="entry-image__delete-img-btn"
          >
            <v-icon small>fa fa-times</v-icon>
          </v-btn>
        </div>
      </v-img>
    </v-card>
  </LightboxImage>
</template>

<script>
import LightboxImage from '@/components/LightboxImage'
export default {
  components: {
    LightboxImage,
  },

  props: {
    image: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.entry-image {
  &__card {
    min-width: 100px;
  }
  &__delete-img-btn {
    opacity: 0;
    .entry-image__img:hover & {
      opacity: 1;
    }
  }
}
</style>
