import { get } from 'lodash'

export function orderEntries(entries, context, ordered = false) {
  return entries.sort((a, b) => {
    const compareDate = a.dateCreated - b.dateCreated

    if (!ordered) return compareDate

    const aOrder = get(a, `order.${context}`)
    const bOrder = get(b, `order.${context}`)

    if (!aOrder && !bOrder) {
      return compareDate
    } else if (!aOrder) {
      return -1
    } else if (!bOrder) {
      return 1
    } else {
      return aOrder - bOrder
    }
  })
}
