import { firebase, db, auth } from '../firebase'
import { absoluteDt } from '../helpers/convert-dates'
import scopeMap from '../helpers/scope-map'

import { collectionData } from 'rxfire/firestore'
import { combineLatest } from 'rxjs'
import { DateTime } from 'luxon'

const uid = auth.currentUser.uid

const References = {
  entries: () => db.collection(`users/${uid}/entries`),
}

export const EntriesDatabase = {
  getEntry: entryId =>
    References.entries()
      .doc(entryId)
      .get()
      .then(txData),

  listenEntry: (entryId, onData) =>
    References.entries()
      .doc(entryId)
      .onSnapshot(doc => onData(txData(doc))),

  listenDayEntries: (dt, onData) => {
    const beginning = absoluteDt(dt).startOf('day')
    const end = beginning.plus({ days: 1 })

    return References.entries()
      .where('dateScheduled', '>=', beginning.toJSDate())
      .where('dateScheduled', '<', end.toJSDate())
      .where('dateUnit', '==', scopeMap.day)
      .where('archived', '==', false)
      .onSnapshot(snap => txSnap(snap, onData))
  },
  listenWeekEntries: (dt, onData) => {
    const beginning = absoluteDt(dt).startOf('week')
    const end = beginning.plus({ weeks: 1 })

    return References.entries()
      .where('dateScheduled', '>=', beginning.toJSDate())
      .where('dateScheduled', '<', end.toJSDate())
      .where('archived', '==', false)
      .onSnapshot(snap => txSnap(snap, onData))
  },
  listenMonthEntries: (dt, onData) => {
    const beginning = absoluteDt(dt).startOf('month')
    const end = beginning.plus({ months: 1 })

    return References.entries()
      .where('dateScheduled', '>=', beginning.toJSDate())
      .where('dateScheduled', '<', end.toJSDate())
      .where('dateUnit', '==', scopeMap.month)
      .where('archived', '==', false)
      .onSnapshot(snap => txSnap(snap, onData))
  },
  listenMonthDayEvents: (dt, onData) => {
    const beginning = absoluteDt(dt).startOf('month')
    const end = beginning.plus({ months: 1 })

    return References.entries()
      .where('dateScheduled', '>=', beginning.toJSDate())
      .where('dateScheduled', '<', end.toJSDate())
      .where('type', '==', 'event')
      .where('dateUnit', '==', scopeMap.day)
      .where('archived', '==', false)
      .onSnapshot(snap => txSnap(snap, onData))
  },
  listenYearEntries: (dt, onData) => {
    const beginning = absoluteDt(dt).startOf('year')
    const end = beginning.plus({ years: 1 })

    return References.entries()
      .where('dateScheduled', '>=', beginning.toJSDate())
      .where('dateScheduled', '<', end.toJSDate())
      .where('dateUnit', '==', scopeMap.year)
      .where('archived', '==', false)
      .onSnapshot(snap => txSnap(snap, onData))
  },
  listenYearDayEvents: (dt, onData) => {
    const beginning = absoluteDt(dt).startOf('year')
    const end = beginning.plus({ years: 1 })

    return References.entries()
      .where('dateScheduled', '>=', beginning.toJSDate())
      .where('dateScheduled', '<', end.toJSDate())
      .where('type', '==', 'event')
      .where('dateUnit', '==', scopeMap.day)
      .where('archived', '==', false)
      .onSnapshot(snap => txSnap(snap, onData))
  },
  observeOverdue$: onData => {
    const observables = []

    Object.keys(scopeMap).map(key => {
      const scope = scopeMap[key]
      const dtNow = DateTime.local()

      const beginning = absoluteDt(dtNow).startOf(key)

      const observable = collectionData(
        References.entries()
          .where('type', '==', 'task')
          .where('dateUnit', '==', scope)
          .where('dateScheduled', '<', beginning.toJSDate())
          .where('dateComplete', '==', null)
          .where('archived', '==', false),
        'id'
      )

      observables.push(observable)
    })

    return combineLatest(observables).subscribe(values =>
      onData(values.reduce((prev, curr) => [...prev, ...curr], []))
    )
  },
  observeListEntries$: (listId, onData) =>
    collectionData(
      References.entries()
        .where('list', '==', listId)
        .where('archived', '==', false),
      'id'
    ).subscribe(onData),
  observePromptEntries$: (promptId, onData) =>
    collectionData(
      References.entries()
        .where('promptId', '==', promptId)
        .where('archived', '==', false),
      'id'
    ).subscribe(onData),
  observeInboxEntries$: onData =>
    collectionData(
      References.entries()
        .where('dateScheduled', '==', null)
        .where('list', '==', null)
        .where('parentId', '==', null)
        .where('tags', '==', null)
        .where('archived', '==', false),
      'id'
    ).subscribe(onData),
  observeTagEntries$: (tagId, onData) =>
    collectionData(
      References.entries()
        .where('tags', 'array-contains', tagId)
        .where('archived', '==', false),
      'id'
    ).subscribe(onData),
  observeSubtasksEntries$: (parentId, onData) =>
    collectionData(
      References.entries()
        .where('parentId', '==', parentId)
        .where('archived', '==', false),
      'id'
    ).subscribe(onData),
}

const txSnap = (snap, onData) => onData(snap.docs.map(txData))

export const txData = (doc, options) => {
  return {
    id: doc.id,
    ...doc.data(options),
  }
}
